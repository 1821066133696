<template>
  <button class="btn btn-outline-primary btn-sm mt-4 px-5" @click="this.$router.go(-1)">
    Back
  </button>
  <div class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0">
    <div class="row p-4">
      <div class="col-md-12">
        <h2 class="text-dark">Upload Logo </h2>
        <hr class="my-0 mb-3" />
      </div>
    </div>

    <div
      class="card-body px-lg-5 py-lg-3"
      id="existingImages"
    
    >
       
       
      <div class="row mr-6 ml-6">
        
        <div class="col-md-4 p-2">
          <div class="card p-1">
            <h3>Current Logo</h3>
            <hr class="my-0 mb-2" />
            <img
              v-if="logoImage"
              :src="root_img_url + logoImage"
              alt=""
              class="w-100"
              style="height: 270px"
            />
            <span v-else class="text-muted text-center">No uploaded Logo</span>
            <hr class="my-1 mb-2" />
            <button class="btn btn-outline-danger" @click="removeLogo" v-if="logoImage">
              Remove
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <h4 class>Upload Logo</h4>
          <hr class="my-0 mb-3" />
          <drop-zone
            :options="optionsLogo"
            @change="get_entity"
            v-if="!logoImage"
          ></drop-zone>
          <span v-else>Remove the Existing Logo image to upload new</span>
        </div>
        <div class="col-md-12">
           <router-link
              :to="{
                name: 'Business Detail',
                params: { uuid: this.$route.params.uuid },
              }"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
              >
                <em class="ni ni-check-bold"></em>
                Done
              </button>
            </router-link>
        </div>
    
      </div>
    </div>
  </div>

    <div class="mt-3 float-right">
   
  </div>
</template>
<script>
import Dropzoned from "../../components/DropzoneComp";
import { alert } from "../../utils/alertUtils";
import axios from "axios";
export default {
  name: "UploadImages",
  components: {
    "drop-zone": Dropzoned,
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
      logoImage: "",
      profileImage: "",
      wallImage: "",
       root_img_url:process.env.VUE_APP_IMG_URL,
      optionsLogo: {
        id: "entity_logo",
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/entities/upload/LogoImage/${this.$route.params.uuid}`,
        maxFilesize: 15,
        maxFiles: 1,
        on_sending: function (file, xhr, form_data) {
          form_data.append("file", file);
          console.log("sending called");
        },
        on_remove: function (file) {
          if (file.status === "success") {
            axios
              .delete(
                `${process.env.VUE_APP_BACKEND_URL}/api/entities/${file.response.uuid}/LogoImage/removal`,
                {
                  headers: {
                    Authorization: `Bearer ${this.options.the.$store.getters.getToken}`,
                  },
                }
              )
              .then((res) => {
                console.log(res);
                this.options.the.$emit("change");
              })
              .catch((err) => {
                console.error(err);
              });
          }
        },
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
        },
      },

      optionsWallpaper: {
        id: "entity_wallpaper",
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/entities/upload/WallImage/${this.$route.params.uuid}`,
        maxFilesize: 0.5,
        maxFiles: 1,
        on_sending: function (file, xhr, form_data) {
          form_data.append("file", file);
          console.log("sending called");
        },
        on_remove: function (file) {
          if (file.status === "success") {
            axios
              .delete(
                `${process.env.VUE_APP_BACKEND_URL}/api/entities/${file.response.uuid}/WallImage/removal`,
                {
                  headers: {
                    Authorization: `Bearer ${this.options.the.$store.getters.getToken}`,
                  },
                }
              )
              .then((res) => {
                console.log(res);
                this.options.the.$emit("change");
              })
              .catch((err) => {
                console.error(err);
              });
          }
        },
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
        },
      },

      optionsProfile: {
        id: "entity_profile",
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/entities/upload/profileImage/${this.$route.params.uuid}`,
        maxFilesize: 1,
        maxFiles: 1,
        on_sending: function (file, xhr, form_data) {
          form_data.append("file", file);
          console.log("sending called");
        },
        on_remove: function (file) {
          if (file.status === "success") {
            axios
              .delete(
                `${process.env.VUE_APP_BACKEND_URL}/api/entities/${file.response.uuid}/ProfileImage/removal`,
                {
                  headers: {
                    Authorization: `Bearer ${this.options.the.$store.getters.getToken}`,
                  },
                }
              )
              .then((res) => {
                console.log(res);
                this.options.the.$emit("change");
              })
              .catch((err) => {
                console.error(err);
              });
          }
        },
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
        },
      },
    };
  },
  methods: {
    removeLogo: function () {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/LogoImage/removal`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Image removed");
          this.get_entity();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    removeProfile: function () {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/ProfileImage/removal`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Image removed");
          this.get_entity();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    removeWallpaper: function () {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/WallImage/removal`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Image removed");
          this.get_entity();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next: function () {
      var UploadImages = document.getElementById("UploadImages");
      var PreviewPage = document.getElementById("PreviewPage");

      UploadImages.classList.add("d-none");
      PreviewPage.classList.remove("d-none");
    },

    previous: function () {
      var UploadImages = document.getElementById("UploadImages");
      var PreviewPage = document.getElementById("PreviewPage");

      UploadImages.classList.remove("d-none");
      PreviewPage.classList.add("d-none");
    },
    get_entity: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.logoImage = res.data.logo;
          this.profileImage = res.data.profileImage;
          this.wallImage = res.data.wall_picture;
          console.log(this.logoImage);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get_entity();
  },
};
</script>